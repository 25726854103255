import React from "react";
import {createRoot} from 'react-dom/client';
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import ScrollAuto from "./components/util/scrollAuto";
import HomeIndex from "./views/home/homeIndex";
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';
import BreederIndex from "./views/breeder/breederIndex";
import CatalogItemIndex from "./views/breeder/catalog/item/catalogItemIndex";
import DropsIndex from "./views/drops/dropsIndex";
import {AppContextProvider} from "./appContext";
import DropIndex from "./views/drops/drop/dropIndex";
import CheckoutIndex from "./views/checkout/checkoutIndex";
import CartIndex from "./views/cart/cartIndex";
import SubscribeIndex from "./views/breeder/subscribe/subscribeIndex";
import CompareIndex from "./views/breeder/subscribe/compare/compareIndex";
import SplashIndex from "./views/public/splash/splashIndex";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
let history = createBrowserHistory();

root.render(
    <ThemeProvider theme={theme}>
        <AppContextProvider>
            <Router history={history}>
                <ScrollAuto>
                    <Switch>
                        <Route path="/" exact component={SplashIndex} />
                        <Route path="/home" exact component={HomeIndex} />
                        <Route path="/cart" exact component={CartIndex} />
                        <Route path="/checkout/:cartId" exact component={CheckoutIndex} />
                        <Route path="/drops" exact component={DropsIndex} />
                        <Route path="/drops/:slug" exact component={DropIndex} />
                        <Route path="/breeder/:username/subscribe" exact component={SubscribeIndex} />
                        <Route path="/breeder/:username" exact component={BreederIndex} />
                        <Route path="/breeder/:username/catalog/item/:slug" exact component={CatalogItemIndex} />
                        <Route path="/breeder/:username/subscribe/compare" exact component={CompareIndex} />
                    </Switch>
                </ScrollAuto>
            </Router>
        </AppContextProvider>
    </ThemeProvider>
);